import * as Actions from './actions';

export const initialState = {
    user: null,
    error: null,
    log: null,
    loading: false,
    audit: false,
    error_fb: null,
    logout: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.LOGIN_SUCCESS_FB:
            return {
                ...state,
                user: action.user
            };
        case Actions.LOGIN_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case Actions.LOGIN_FAILURE_FB:
            return {
                ...state,
                error_fb: action.error
            };
        case Actions.LOGIN_FB:
            return {
                ...state,
                log: action.result
            };
        case Actions.FETCH_USER_LOGIN_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case Actions.CREATE_USER:
            return {
                ...state,
                loading: true
            };
        case Actions.CREATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user
            };
        case Actions.CREATE_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case Actions.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.result
            };
        case Actions.AUDIT_AUTH_SUCCESS:
            return {
                ...state,
                audit: true
            };
        case Actions.AUDIT_AUTH_FAIL:
            return {
                ...state,
                error: action.error
            };
        case Actions.LOGOUT_SUCCESS:
            return {
                ...state,
                logout: action.result
            };
        case Actions.LOGOUT_FAILED:
            return {
                ...state,
                error: action.error
            }
        default:
            return state;
    }
}
import { select, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import * as Actions from './actions';
import { getParamsClientMac } from '../../selectors';
import { getUserLogin, register, auditAuth, logout } from '../../services/auth';

function* handleLoginFailure(action) {
	try {
		const client_mac = yield select(getParamsClientMac);
		if (client_mac != null) {
			const result = yield call(getUserLogin, { client_mac });
			if (result != null) {
				yield put(Actions.loginSuccess(result));
			}
		}
	} catch (error) {
		yield put(Actions.fetchUserLoginFailed(error));
	}
}

function* handleCreateUser(action) {
	try {
		const user = yield call(register, action.user);
		yield put(Actions.createUserSuccess(user));
	} catch (error) {
		yield put(Actions.createUserFailed(error));
	}
}

function* handleAuditAuth(action) {
	try {
		const audit = yield call(auditAuth, action.audit);
		yield put(Actions.auditAuthSuccess(audit));
	} catch (error) {
		yield put(Actions.auditAuthFailed(error));
	}
}

function* handleLogout(action) {
	try {
		const result = yield call(logout);

		yield put(Actions.logoutSuccess(result));
	} catch (error) {
		yield put(Actions.logoutFailed(error));
	}
}

export default function* saga() {
	yield takeEvery(Actions.LOGIN_FAILURE, handleLoginFailure);
	yield takeLatest(Actions.CREATE_USER, handleCreateUser);
	yield takeLatest(Actions.AUDIT_AUTH, handleAuditAuth);
	yield takeLatest(Actions.LOGOUT, handleLogout);
}

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Login from '../Login';
import Register from '../Register';
import Welcome from '../Welcome';
import requireAuth from '../../HOC/RequireAuth';

class AppRouter extends React.Component {
	render() {
		return (
			<Switch>
				<Route exact path={'/:centerId/dashboard'} component={requireAuth(Dashboard)} />
				<Route exact path={'/login'} component={Login} />
				<Route exact path={'/:centerId/login'} component={Login} />
				<Route exact path={'/:centerId/register'} component={Register} />
				<Route exact path={'/:centerId/welcome'} component={requireAuth(Welcome)} />
				<Route path='*' render={() => <Redirect to={{ pathname: '/register' }} />} />
			</Switch>
		);
	}
}

export default AppRouter;

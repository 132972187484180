export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_FB = "LOGIN_SUCCESS_FB";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_FAILURE_FB = "LOGIN_FAILURE_FB";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_SUCCESS_WIFI = "LOGOUT_SUCCESS_WIFI";
export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const AUDIT_AUTH = "AUDIT_AUTH";
export const AUDIT_AUTH_SUCCESS = "AUDIT_AUTH_SUCCESS";
export const AUDIT_AUTH_FAIL = "AUDIT_AUTH_FAIL";
export const LOGIN_FB = "LOGIN_FB";
export const FETCH_USER_LOGIN_FAILURE = "FETCH_USER_LOGIN_FAILURE";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

export const logout = () => ({
  type: LOGOUT
});

export const logoutSuccess = result => ({
  type: LOGOUT_SUCCESS,
  result
});

export const logoutFailed = error => ({
  type: LOGOUT_FAILED,
  error
});

export const loginFb = result => ({
  type: LOGIN_FB,
  result
});

export const loginSuccess = result => ({
  type: LOGIN_SUCCESS,
  result
});

export const loginSuccessFb = user => ({
    type: LOGIN_SUCCESS_FB,
    user
});

export const loginFailure = error => ({
  type: LOGIN_FAILURE,
  error
});

export const loginFailureFb = error => ({
  type: LOGIN_FAILURE_FB,
  error
});

export const fetchUserLoginFailed = error => ({
  type: FETCH_USER_LOGIN_FAILURE,
  error
});

export const createUser = user => ({
  type: CREATE_USER,
  user
});

export const createUserSuccess = user => ({
  type: CREATE_USER_SUCCESS,
  user
});

export const createUserFailed = error => ({
  type: CREATE_USER_FAILED,
  error
});

export const auditAuth = audit => ({
  type: AUDIT_AUTH,
  audit
});

export const auditAuthSuccess = result => ({
  type: AUDIT_AUTH_SUCCESS,
  result
});

export const auditAuthFailed = error => ({
  type: AUDIT_AUTH_FAIL,
  error
});
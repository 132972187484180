import appLocaleData from 'react-intl/locale-data/en';
import enMessages from '../locales/en-US.json';

const EnLang = {
    messages: {
        ...enMessages
    },
    locale: 'en-US',
    data: appLocaleData,
};

export default EnLang;
import appLocaleData from 'react-intl/locale-data/zh';
import zhMessages from '../locales/zh-CN.json';

const ZhLang = {
    messages: {
        ...zhMessages,
    },
    locale: 'zh-CN',
    data: appLocaleData
};

export default ZhLang;
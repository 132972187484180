import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import * as authActions from '../../redux/auth/actions';
import * as centerActions from '../../redux/center/actions';

class Dashboard extends Component {
	state = {
		stage: 'connecting',
	};

	componentDidMount() {
		document.body.style.backgroundColor = '#4019FF';
		window.scrollTo(0, 0);
		const { user, auditAuth, queryParams, match, fetchCenterWifi, fetchCenter } = this.props;
		const center_id = match.params.centerId;
		fetchCenter(center_id);
		if (user != null && user.user_id) {
			const user_id = user.user_id;
			const audit = {
				user_id: user_id,
				client_mac: queryParams.client_mac,
				center_id,
			};
			auditAuth(audit);
			fetchCenterWifi(center_id, user_id);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.error_wifi !== prevProps.error_wifi && this.props.error_wifi !== null) {
			this.setState({
				stage: 'warning failure',
			});
		}
	}

	render() {
		const { stage } = this.state;
		const { user } = this.props;
		return (
			<React.Fragment>
				{stage === 'connecting' || stage === 'warning failure' ? (
					<main id='main' className={stage}>
						<Header wifi={false} caption={stage === 'connecting' ? 'Connecting' : 'Oops, please try again!'} />
						{user ? (
							<section id='connection' className='visible'>
								<ul id='tether'>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
									<li></li>
								</ul>
								<figure id='user'>
									<img src={process.env.REACT_APP_DEFAULT_AVATAR} alt='Display' />
								</figure>
							</section>
						) : null}

						<Footer />
					</main>
				) : null}
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.auth.user,
		queryParams: state.queryParams.queryParams,
		center: state.center.center,
		error_wifi: state.wifi.error,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchCenterWifi(center_id, user_id) {
			dispatch(centerActions.fetchCenterWifi(center_id, user_id));
		},
		auditAuth(audit) {
			dispatch(authActions.auditAuth(audit));
		},
		fetchCenter(center_id) {
			dispatch(centerActions.fetchCenter(center_id));
		},
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import * as authActions from '../../redux/auth/actions';
import { fetchCenterTnc } from '../../services/center';
import ClipLoader from 'react-spinners/ClipLoader';

class Register extends Component {
	state = {
		termsVisible: false,
		age: '',
		postcode: '',
		agree: '',
		tncOpen: false,
		tnc: '',
		loading: false,
		formErrors: {
			agree: '',
			age: '',
			postcode: '',
		},
		validations: {
			age: false,
			postcode: false,
			agree: false,
		},
		formValid: false,
		submitted: false,
	};

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user && this.props.user !== null) {
			const centerId = this.props.match.params.centerId || '000';
			this.props.history.push(`/${centerId}/dashboard`);
		}
	}

	handleTermsClick = (e) => {
		e.preventDefault();
		this.setState(
			{
				termsVisible: true,
			},
			() => this.handleFetchTnc()
		);
	};

	handleFetchTnc = () => {
		const { tnc, loading } = this.state;
		if (!tnc && !loading) {
			this.setState({
				loading: true,
			});
			const center_id = this.props.match.params.centerId || '000';
			fetchCenterTnc(center_id)
				.then((result) => {
					this.setState({
						loading: false,
						tnc: result,
					});
				})
				.catch((error) => {
					this.setState({
						loading: false,
					});
				});
		}
	};

	handleTermsCancel = (e) => {
		e.preventDefault();
		this.setState({
			termsVisible: false,
		});
	};

	handleTermsOk = (e) => {
		e.preventDefault();
		this.setState(
			{
				termsVisible: false,
				agree: 'on',
			},
			() => this.validateField('agree', 'on')
		);
	};

	handleUserInput = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		this.setState(
			{
				[name]: value,
			},
			() => {
				this.validateField(name, value);
			}
		);
	};

	validateField = (fieldName, value) => {
		let fieldValidationErrors = this.state.formErrors;
		let validations = this.state.validations;
		switch (fieldName) {
			case 'age':
				if (value.length) {
					validations.age = true;
				} else {
					validations.age = false;
					fieldValidationErrors.age = validations.age ? '' : 'Age is required';
				}
				break;
			case 'postcode':
				if (value.length) {
					validations.postcode = /^\d+$/.test(value);
					fieldValidationErrors.postcode = validations.postcode ? '' : 'Postcode must be integer';
				} else {
					validations.postcode = value.length === 0 ? false : true;
					fieldValidationErrors.postcode = validations.postcode ? '' : 'Postcode is required';
				}
				break;
			case 'agree':
				validations.agree = true;
				break;
			default:
				break;
		}
		this.setState(
			{
				formErrors: fieldValidationErrors,
				validations: validations,
			},
			this.validateForm
		);
	};

	validateForm = () => {
		let valid = this.isFormValid();
		this.setState({
			formValid: valid,
		});
	};

	isFormValid = () => {
		let validations = this.state.validations;
		for (let val in validations) {
			if (validations[val] === false) {
				return false;
			}
		}
		return true;
	};

	hasError(error) {
		return error.length === 0 ? false : true;
	}

	showError(error) {
		if (error.length === 0) {
			return '';
		}
		return (
			<span className='form-error is-visible' id='uuid'>
				{error}
			</span>
		);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { agree, age, postcode } = this.state;
		const { createUser } = this.props;
		this.setState({
			submitted: true,
		});
		createUser({ agree, age, postcode });
	};

	componentDidMount() {
		document.body.style.backgroundColor = '#000';
		this.height = this.main.clientHeight;
		console.dir(this.height);
	}

	touchScreen = () => {
		window.scrollTo(0, this.height);
	};
	render() {
		const { termsVisible, loading, tnc } = this.state;
		return (
			<main ref={(main) => (this.main = main)} id='main' className={termsVisible ? 'inactive terms' : 'active register'}>
				<Header caption={''} />
				<section id='register' className={termsVisible ? 'hidden' : 'visible'}>
					<form action='#' method='get' data-state-change='survey'>
						<fieldset className='split'>
							<label htmlFor='age'>Age</label>
							<select name='age' id='age' required onChange={this.handleUserInput} onBlur={this.touchScreen}>
								<option disabled selected={true} value=''></option>
								<optgroup label='Age Groups'>
									<option value='01-17'>Under 17</option>
									<option value='18-24'>18-24</option>
									<option value='25-34'>25-34</option>
									<option value='35-44'>35-44</option>
									<option value='45-54'>45-54</option>
									<option value='55-99'>Over 55</option>
								</optgroup>
							</select>
							<aside className='icon'></aside>
						</fieldset>
						<fieldset className={`${this.hasError(this.state.formErrors.postcode) ? 'invalidated' : 'validated'} ${'split'}`}>
							{this.hasError(this.state.formErrors.postcode) ? (
								<label htmlFor='email' className='error'>
									Please Enter Valid Postcode
								</label>
							) : (
								<label htmlFor='email'>Postcode</label>
							)}
							<input
								type='tel'
								pattern='\d*'
								name='postcode'
								id='postcode'
								minLength='4'
								maxLength='4'
								required
								onChange={this.handleUserInput}
								onBlur={this.touchScreen}
							/>
							<aside className='icon'></aside>
						</fieldset>
						<fieldset>
							<input type='checkbox' name='agree' id='agree' required checked={this.state.agree} onChange={this.handleUserInput} />
							<label htmlFor='agree'>
								I agree to the{' '}
								<a href='/' data-state-change='terms' onClick={this.handleTermsClick}>
									terms and conditions
								</a>
								.
							</label>
						</fieldset>
						<fieldset className={!this.state.formValid || this.state.submitted ? 'invalidated-button' : 'validated-button'}>
							<input
								type='submit'
								name='submit'
								id='submit'
								value='Connect'
								className='button'
								onClick={this.handleSubmit}
								disabled={!this.state.formValid || this.state.submitted}
							/>
						</fieldset>
					</form>
				</section>

				<section id='terms' className={termsVisible ? 'visible' : 'hidden'}>
					<form action='#' method='get' data-state-change='register'>
						<article>
							<h1>Terms &amp; Conditions</h1>
							{loading ? <ClipLoader sizeUnit={'px'} size={50} color={'#123abc'} loading={true} /> : <p>{tnc && tnc.tnc ? tnc.tnc : ''}</p>}
						</article>
						<footer>
							<input type='reset' className='button' value='Cancel' onClick={this.handleTermsCancel} />
							<input type='submit' className='button' value='I agree' onClick={this.handleTermsOk} />
						</footer>
					</form>
				</section>
				<Footer />
			</main>
		);
	}
}

function mapStateToProps(state, props) {
	return {
		loading: state.auth.loading,
		user: state.auth.user,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		createUser(user) {
			dispatch(authActions.createUser(user));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);

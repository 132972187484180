import React, { Component } from 'react';
import { connect } from 'react-redux';

export default function(ComposedComponent) {
    class Authentication extends Component {
        componentWillMount() {
            if (!this.props.authenticated) {
                const centerId = this.props.match.params.centerId || '000';
                this.props.history.push(`/${centerId}/register`);
            }
        }

        componentWillUpdate(nextProps) {
            if (!nextProps.authenticated) {
                const centerId = nextProps.match.params.centerId || '000';
                // this.props.history.push('/login');
                this.props.history.push(`/${centerId}/register`);
            }
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    function mapStateToProps(state) {
        return {
            authenticated: !!state.auth.user
        };
    }

    return connect(mapStateToProps)(Authentication);
}
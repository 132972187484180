import React, { Component } from 'react';
class Header extends Component {
	state = {
		dropdown: false,
		logout: false,
	};
	toggleDropdown = (e) => {
		e.preventDefault();
		this.setState({
			dropdown: !this.state.dropdown,
		});
	};
	handleLogout = (e) => {
		e.preventDefault();
		const { logout } = this.props;
		logout();
		this.setState({
			logout: true,
		});
	};
	render() {
		const { wifi = false, caption = 'Loading' } = this.props;
		const { dropdown, logout } = this.state;
		return (
			<header id='header'>
				{wifi ? (
					<div className='logged-in'>
						<figure id='user' className='icon' onClick={this.toggleDropdown}>
							<img src={process.env.REACT_APP_DEFAULT_AVATAR} alt='Display' />
							<input type='checkbox' />
							<dl style={{ display: dropdown ? 'block' : 'none' }}>
								<dd>
									<a href='/' data-state-change='login' onClick={this.handleLogout}>
										<strong>Log out</strong>
									</a>
								</dd>
							</dl>
						</figure>
						<h1>Hello!</h1>
						<p>{logout ? 'Already logged out.' : null}</p>
					</div>
				) : (
					<div className='not-logged-in'>
						<figure id='logo'>
							<div></div>
							<div></div>
							<div></div>
						</figure>
						<h1 id='title'>Free Wi-Fi</h1>
						<p id='caption'>{caption}</p>
					</div>
				)}
			</header>
		);
	}
}

export default Header;

import Cookies from 'universal-cookie';
import axiosInstance from './axiosInstance';
import { getDeviceType, getUserAgent } from './utilities';

const authCookies = new Cookies();

export function initAuthentication(cb) {
	const user_id = authCookies.get('user_id');
	/* if still collecting user, redirects user to login page
	so it accepts new terms & conditions without email collection
	*/
	const user_email = authCookies.get('email');
	if (user_email) {
		authCookies.set('email', '', { path: '/' });
		authCookies.set('first_name', '', { path: '/' });
		authCookies.set('last_name', '', { path: '/' });
	}
	return user_id && !user_email ? cb(null, { user_id }) : cb('No user session', null);
}

export function auditAuth({ user_id, center_id, client_mac }) {
	let device_type = getDeviceType();
	let user_agent = getUserAgent();
	return axiosInstance
		.post(`/user/audit`, {
			user_id,
			center_id,
			client_mac,
			device_type,
			user_agent,
		})
		.then((result) => Promise.resolve(result.data))
		.catch((err) => Promise.reject(err));
}

export function getUserLogin({ client_mac }) {
	return axiosInstance
		.get(`/user/login?client_mac=${client_mac}`)
		.then((result) => {
			if (result.status !== 200) {
				return Promise.reject(result);
			}
			//set cookie
			if (result.data !== null && result.data !== '') {
				setAuthSession(result.data);
				return Promise.resolve(result.data);
			}
			return Promise.reject('No such user!');
		})
		.catch((err) => Promise.reject(err));
}

export function register({ agree, age, postcode }) {
	return axiosInstance
		.post(`/user`, {
			agree,
			age,
			postcode,
		})
		.then((result) => {
			if (result.status !== 200) {
				return Promise.reject(result);
			}
			//set cookie
			const user_id = result.data.id ? result.data.id : null;
			setAuthSession({ user_id });
			return Promise.resolve(result.data);
		})
		.catch((error) => Promise.reject(error));
}

export function setAuthSession({ user_id }) {
	let cookieDate = new Date();
	cookieDate.setMonth(cookieDate.getMonth() + 6);
	if (user_id) {
		authCookies.set('user_id', JSON.stringify(user_id), {
			path: '/',
			expires: cookieDate,
		});
	}
}

export function setWiFiQueryParameters(params) {
	let cookieDate = new Date();
	cookieDate.setMonth(cookieDate.getMonth() + 6);
	authCookies.set('queryParams', JSON.stringify(params), {
		path: '/',
		expires: cookieDate,
	});
}

export function getWifiQueryParameters() {
	const queryParams = authCookies.get('queryParams');
	return queryParams;
}

export function setCenterId(centerId) {
	let cookieDate = new Date();
	cookieDate.setMonth(cookieDate.getMonth() + 6);
	if (centerId) {
		authCookies.set('center_id', JSON.stringify(centerId), {
			path: '/',
			expires: cookieDate,
		});
	}
}

export function getCenterId() {
	const centerId = authCookies.get('center_id');
	return centerId;
}

export function setSiteNameCookie(site_name) {
	let cookieDate = new Date();
	cookieDate.setMonth(cookieDate.getMonth() + 6);
	if (site_name) {
		authCookies.set('site_name', JSON.stringify(site_name), {
			path: '/',
			expires: cookieDate,
		});
	}
}

export function getSiteNameCookie() {
	const site_name = authCookies.get('site_name');
	return site_name;
}

export function logout() {
	const user_id = authCookies.get('user_id');
	authCookies.remove('user_id', { path: '/' });
	authCookies.remove('site_name', { path: '/' });
	return axiosInstance
		.delete(`/user/login/${user_id}`)
		.then((result) => Promise.resolve(result.data))
		.catch((error) => Promise.reject(error));
}

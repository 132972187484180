import axiosInstance from './axiosInstance';

export function fetchWifiDetails({ center_id, wlan_id, ap_mac, client_mac, site_name, ssid }) {
	const callbackUrl = `${process.env.REACT_APP_CALLBACK_URL}/${center_id}/welcome`;
	return axiosInstance
		.post('/wifi', {
			wlan_id,
			ap_mac,
			client_mac,
			callbackUrl,
			site_name,
			ssid,
		})
		.then((result) => {
			return Promise.resolve({ mistLocation: result.data.location });
		})
		.catch((error) => Promise.reject(error));
}

export function getWifi(mistLocation) {
	window.location.href = mistLocation;
}

import * as Actions from './actions';

const initialState = {
	error: null,
	fetching: false,
	center: null,
	campaign: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case Actions.FETCH_CENTER:
			return {
				...state,
				center: { id: action.center_id },
			};
		case Actions.FETCH_CENTER_CAMPAIGN_SUCCESS:
			return {
				...state,
				campaign: action.campaign,
			};
		case Actions.FETCH_CENTER_CAMPAIGN_FAILED:
			return {
				...state,
				error: action.error,
			};
		case Actions.FETCH_WEATHER_SUCCESS:
			return {
				...state,
				weather: action.weather,
			};
		case Actions.FETCH_WEATHER_FAILED:
			return {
				...state,
				error: action.error,
			};
		default:
			return state;
	}
}

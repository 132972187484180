export const FETCH_WIFI_DETAILS = "FETCH_WIFI_DETAILS";
export const FETCH_WIFI_DETAILS_SUCCESS = "FETCH_WIFI_DETAILS_SUCCESS";
export const FETCH_WIFI_DETAILS_FAILED = "FETCH_WIFI_DETAILS_FAILED";
export const GET_WIFI = "GET_WIFI";
export const GET_WIFI_FAILED = "GET_WIFI_FAILED";

export const fetchWifiDetails = params => ({
    type: FETCH_WIFI_DETAILS,
    params
});

export const fetchWifiDetailsSuccess = wifiDetails => ({
    type: FETCH_WIFI_DETAILS_SUCCESS,
    wifiDetails
});

export const fetchWifiDetailsFailed = error => ({
    type: FETCH_WIFI_DETAILS_FAILED,
    error
});

export const getWifi = mistLocation => ({
    type: GET_WIFI,
    mistLocation
});

export const getWifiFailed = error => ({
    type: GET_WIFI_FAILED,
    error
});
export const FETCH_CENTER_WIFI = 'FETCH_CENTER_WIFI';
export const FETCH_CENTER = 'FETCH_CENTER';
export const FETCH_CENTER_CAMPAIGN = 'FETCH_CENTER_CAMPAIGN';
export const FETCH_CENTER_CAMPAIGN_SUCCESS = 'FETCH_CENTER_CAMPAIGN_SUCCESS';
export const FETCH_CENTER_CAMPAIGN_FAILED = 'FETCH_CENTER_CAMPAIGN_FAILED';
export const FETCH_WEATHER = 'FETCH_WEATHER';
export const FETCH_WEATHER_SUCCESS = 'FETCH_WEATHER_SUCCESS';
export const FETCH_WEATHER_FAILED = 'FETCH_WEATHER_FAILED';
export const SUBMIT_CENTER_ANSWERS_SUCCESS = 'SUBMIT_CENTER_ANSWERS_SUCCESS';

export const fetchWeather = (center_id) => ({
	type: FETCH_WEATHER,
	center_id,
});

export const fetchWeatherSuccess = (weather) => ({
	type: FETCH_WEATHER_SUCCESS,
	weather,
});

export const fetchWeatherFailed = (error) => ({
	type: FETCH_WEATHER_FAILED,
	error,
});

export const fetchCenter = (center_id) => ({
	type: FETCH_CENTER,
	center_id,
});

export const fetchCenterCampaign = (center_id, user_id) => ({
	type: FETCH_CENTER_CAMPAIGN,
	center_id,
	user_id,
});

export const fetchCenterCampaignSuccess = (campaign) => ({
	type: FETCH_CENTER_CAMPAIGN_SUCCESS,
	campaign,
});

export const fetchCenterCampaignFailed = (error) => ({
	type: FETCH_CENTER_CAMPAIGN_FAILED,
	error,
});
export const submitCenterAnswersSuccess = (result) => ({
	type: SUBMIT_CENTER_ANSWERS_SUCCESS,
	result,
});

export const fetchCenterWifi = (center_id, user_id) => ({
	type: FETCH_CENTER_WIFI,
	center_id,
	user_id,
});

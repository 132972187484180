import * as Actions from './actions';
import { select, put, call, takeLatest } from 'redux-saga/effects';
import { fetchCenterCampaign, fetchCenterWeather } from '../../services/center';
import { getSiteNameCookie } from '../../services/auth';
import { getCenter, getQueryParams } from '../../selectors';
import * as wifiActions from '../wifi/actions';

function* handleFetchCenterWifi(action) {
	try {
		const { id } = yield select(getCenter);
		const { wlan_id, ap_mac, client_mac, site_name, ssid } = yield select(getQueryParams);
		yield put(wifiActions.fetchWifiDetails({ wlan_id, ap_mac, client_mac, site_name, ssid, center_id: id }));
	} catch (error) {
		yield put(wifiActions.fetchWifiDetailsFailed(error));
	}
}
function* handleFetchCenterCampaign(action) {
	try {
		const campaign = yield call(fetchCenterCampaign, { center_id: action.center_id, user_id: action.user_id });
		yield put(Actions.fetchCenterCampaignSuccess(campaign));
	} catch (error) {
		yield put(Actions.fetchCenterCampaignFailed(error));
	}
}

function* handleFetchWeather(action) {
	try {
		const site_name = yield call(getSiteNameCookie);
		if (site_name != null) {
			const center_id = action.center_id;
			const obj = { site_name, center_id };
			const weather = yield call(fetchCenterWeather, obj);
			yield put(Actions.fetchWeatherSuccess(weather));
		}
	} catch (error) {
		yield put(Actions.fetchWeatherFailed(error));
	}
}

function* handleSubmitCenterAnswersSuccess(action) {
	try {
		const { id } = yield select(getCenter);
		const { wlan_id, ap_mac, client_mac, site_name, ssid } = yield select(getQueryParams);
		yield put(wifiActions.fetchWifiDetails({ wlan_id, ap_mac, client_mac, site_name, ssid, center_id: id }));
	} catch (error) {
		yield put(wifiActions.fetchWifiDetailsFailed(error));
	}
}

export default function* saga() {
	yield takeLatest(Actions.FETCH_CENTER_WIFI, handleFetchCenterWifi);
	yield takeLatest(Actions.FETCH_CENTER_CAMPAIGN, handleFetchCenterCampaign);
	yield takeLatest(Actions.SUBMIT_CENTER_ANSWERS_SUCCESS, handleSubmitCenterAnswersSuccess);
	yield takeLatest(Actions.FETCH_WEATHER, handleFetchWeather);
}

export const SET_QUERY_PARAMS_SUCCESS = 'SET_QUERY_PARAMS_SUCCESS';
export const SET_QUERY_PARAMS_FAILED = 'SET_QUERY_PARAMS_FAILED';
export const SET_WIFI_QUERY_PARAMS = 'SET_WIFI_QUERY_PARAMS';

export const setQueryParamsSuccess = queryParams => ({
    type: SET_QUERY_PARAMS_SUCCESS,
    queryParams
});

export const setQueryParamsFailed = error => ({
    type: SET_QUERY_PARAMS_FAILED,
    error
});

export const setWiFiQueryParams = queryParams => ({
    type: SET_WIFI_QUERY_PARAMS,
    queryParams
});
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import * as centerActions from '../../redux/center/actions';
import * as authActions from '../../redux/auth/actions';

class Welcome extends Component {
	state = {
		wifi: false,
		avatar: process.env.REACT_APP_DEFAULT_AVATAR,
	};
	componentDidMount() {
		const { match, user, fetchCenterCampaign, fetchCenterWeather } = this.props;
		const center_id = match.params.centerId;
		fetchCenterWeather(center_id);
		if (user !== null && user.user_id !== null) {
			const user_id = user.user_id;
			fetchCenterCampaign(center_id, user_id);
		}
		setTimeout(() => {
			this.setState({
				wifi: true,
			});
		}, 1000);
	}

	componentDidUpdate(prevProps) {
		if (this.props.campaign !== prevProps.campaign) {
			this.setState({
				wifi: true,
			});
		}
	}

	renderWeather() {
		const { weather } = this.props;
		let parts = weather.address.split(',');
		let part = parts.length > 1 ? parts[parts.length - 2].trim() : '';
		let suburbParts = part.split(' ');
		let suburb = suburbParts.join(' ');
		return (
			<figure id='weather'>
				<h1>{Math.floor(weather.temp)}&deg;</h1>
				<dl>
					<dt>{suburb}</dt>
					<dd>{weather.weather}</dd>
				</dl>
			</figure>
		);
	}
	render() {
		const { wifi } = this.state;
		const { user, campaign, weather, logout } = this.props;
		return (
			<main id='main' className={wifi ? 'inactive welcome' : 'success'}>
				<Header wifi={wifi} user={user} caption={'Connected'} logout={logout} />
				{wifi ? (
					<section id='welcome' className='visible'>
						{campaign && campaign.landing_splash_content ? (
							<a id='campaign' href={campaign.landing_url}>
								<img src={campaign.landing_splash_content} alt='Campaign' />
							</a>
						) : null}

						{weather ? this.renderWeather() : null}
						<ul>
							<li>
								<a href='https://www.google.com/' target='_blank' rel='noopener noreferrer' className='square button icon google'>
									Google
								</a>
							</li>
							<li>
								<a href='https://www.facebook.com/' target='_blank' rel='noopener noreferrer' className='square button icon facebook'>
									Facebook
								</a>
							</li>
							<li>
								<a href='https://www.twitter.com/' target='_blank' rel='noopener noreferrer' className='square button icon twitter'>
									Twitter
								</a>
							</li>
							<li>
								<a href='https://www.instagram.com/' target='_blank' rel='noopener noreferrer' className='square button icon instagram'>
									Instagram
								</a>
							</li>
							<li>
								<a href='https://www.linkedin.com/' target='_blank' rel='noopener noreferrer' className='square button icon linkedin'>
									LinkedIn
								</a>
							</li>
							<li>
								<a href='https://www.youtube.com/' target='_blank' rel='noopener noreferrer' className='square button icon youtube'>
									YouTube
								</a>
							</li>
						</ul>
					</section>
				) : (
					<section id='connection' className='visible'>
						<ul id='tether'>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
							<li></li>
						</ul>
						<figure id='user'>
							<img src={this.state.avatar} alt='Display' />
						</figure>
					</section>
				)}
				<Footer />
			</main>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.auth.user,
		campaign: state.center.campaign,
		weather: state.center.weather,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchCenterCampaign(center_id, user_id) {
			dispatch(centerActions.fetchCenterCampaign(center_id, user_id));
		},
		fetchCenterWeather(center_id) {
			dispatch(centerActions.fetchWeather(center_id));
		},
		logout() {
			dispatch(authActions.logout());
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

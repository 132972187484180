import { put, call, takeLatest } from 'redux-saga/effects';
import * as Actions from './actions';
import { setSiteNameCookie, setWiFiQueryParameters, setAuthSession, getWifiQueryParameters } from '../../services/auth';

function* handleQueryParamsSuccess(action) {
	try {
		const site_name = action.queryParams && action.queryParams.site_name;
		if (site_name != null) {
			yield call(setWiFiQueryParameters, action.queryParams);
			yield call(setSiteNameCookie, site_name);
		} else {
			//login with facebook, google, twitter
			const user_id = action.queryParams && action.queryParams.user_id;
			if (user_id != null) {
				//save auth session
				yield call(setAuthSession, { user_id });
				//restore wifi query params from cookie
				const queryParams = yield call(getWifiQueryParameters);
				yield put(Actions.setWiFiQueryParams(queryParams));
			}
		}
	} catch (error) {
		yield put(Actions.setQueryParamsFailed(error));
	}
}

export default function* saga() {
	yield takeLatest(Actions.SET_QUERY_PARAMS_SUCCESS, handleQueryParamsSuccess);
}

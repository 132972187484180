import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './redux/store';
import AppLocale from './languageProvider';
import { getLocale } from './services/locale';
import * as queryParamsService from './services/queryParams';
import * as queryParamsActions from './redux/queryParams/actions';
import * as authService from './services/auth';
import * as authActions from './redux/auth/actions';

import './scss/styles.css';
import './scss/dev.css';
import App from './containers/App/App';
import { unregister } from './registerServiceWorker';

queryParamsService.initQueryParams((err, result) => {
	if (err) {
		store.dispatch(queryParamsActions.setQueryParamsSuccess({}));
	} else {
		store.dispatch(queryParamsActions.setQueryParamsSuccess(result));
	}
});

authService.initAuthentication((err, result) => {
	if (err) {
		store.dispatch(authActions.loginFailure(err));
	} else {
		store.dispatch(authActions.loginSuccess(result));
	}
});

const locale = getLocale();
const currentAppLocale = AppLocale[locale] || AppLocale.en;

ReactDOM.render(
	<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</Provider>
	</IntlProvider>,
	document.getElementById('root')
);
unregister();

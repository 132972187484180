import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { setCenterId, getCenterId } from '../../services/auth';
import { getDeviceType } from '../../services/utilities';

class Login extends Component {
	state = {
		centerId: '000',
	};

	componentDidMount() {
		let centerId = this.props.match.params.centerId;
		if (centerId != null) {
			//still in react
			this.setState({
				centerId,
			});
			//save to cookie
			setCenterId(centerId);
		} else {
			//load from cookie
			centerId = getCenterId();
			const { user } = this.props;
			if (user != null) {
				this.props.history.push(`/${centerId}/dashboard`);
			} else {
				this.setState({
					centerId,
				});
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user && this.props.user !== null) {
			let centerId = this.props.match.params.centerId;
			if (centerId == null) {
				centerId = getCenterId();
			}
			this.props.history.push(`/${centerId}/dashboard`);
		}
	}

	render() {
		const { centerId } = this.state;
		getDeviceType();
		return (
			<main id='main' className='inactive login'>
				<Header caption={' '} />
				<section id='login' className='visible'>
					<p>
						<Link className='button login-btn' to={`/${centerId}/register`}>
							Continue to <strong>login</strong>
						</Link>
					</p>
				</section>
				<Footer />
			</main>
		);
	}
}

function mapStateToProps(state, props) {
	return {
		user: state.auth.user,
		queryParams: state.queryParams,
	};
}

function mapDispatchToProps(dispatch) {
	return {};
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

import * as Actions from './actions';

const initialState = {
    wifiDetails: null,
    error: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_WIFI_DETAILS_SUCCESS:
            return {
                ...state,
                wifiDetails: action.wifiDetails
            };
        case Actions.FETCH_WIFI_DETAILS_FAILED:
            return {
                ...state,
                error: action.error
            }
        case Actions.GET_WIFI_FAILED:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
import * as Actions from './actions';
import { put, call, takeLatest } from 'redux-saga/effects';
import { fetchWifiDetails, getWifi } from '../../services/wifi';

function* handleFetchWifiDetails(action) {
	try {
		const wifiDetails = yield call(fetchWifiDetails, action.params);
		//redirect to mist to get wifi connection
		yield put(Actions.fetchWifiDetailsSuccess(wifiDetails));
		yield put(Actions.getWifi(wifiDetails.mistLocation));
	} catch (error) {
		yield put(Actions.fetchWifiDetailsFailed(error));
	}
}

function* handleGetWifi(action) {
	try {
		yield call(getWifi, action.mistLocation);
	} catch (error) {
		yield put(Actions.getWifiFailed(error));
	}
}

export default function* saga() {
	yield takeLatest(Actions.FETCH_WIFI_DETAILS, handleFetchWifiDetails);
	yield takeLatest(Actions.GET_WIFI, handleGetWifi);
}

import * as Actions from './actions';

export const initialState = {
    queryParams: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_QUERY_PARAMS_SUCCESS:
            return {
                ...state,
                queryParams: action.queryParams
            };
        case Actions.SET_QUERY_PARAMS_FAILED:
            return {
                ...state,
                error: action.error
            }
        case Actions.SET_WIFI_QUERY_PARAMS:
            return {
                ...state,
                queryParams: action.queryParams
            };
        default:
            return state;
    }
}
import { fork } from 'redux-saga/effects';
import authSaga from './auth/saga';
import centerSaga from './center/saga';
import wifiSaga from './wifi/saga';
import queryParamsSaga from './queryParams/saga';

export default function* rootSaga(getState) {
    yield fork(authSaga);
    yield fork(centerSaga);
    yield fork(wifiSaga);
    yield fork(queryParamsSaga);
}
import platform from 'platform';

export function getDeviceType() {
    const product = platform.product;
    if (product != null) {
        return product;
    }
    const os = platform.os;
    if (os != null && os.family != null) {
        return os.family;
    }
    return "Unknown";
}

export function getUserAgent() {
    return platform.ua || "Unknown";
}
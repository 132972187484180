import auth from './auth/reducer';
import queryParams from './queryParams/reducer';
import center from './center/reducer';
import wifi from './wifi/reducer';

export default {
    auth,
    queryParams,
    center,
    wifi
};
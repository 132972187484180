import React, { Component } from 'react';
import Logo from '../../images/logo.png';

class Footer extends Component {
    render() {
		const { survey = false } = this.props;
        return (
            <footer id="footer" style={survey ? { zIndex: 1030, position: 'fixed', bottom: 0, left: 0, width: '100%', boxSizing: 'border-box' } : {}}>
				<cite>
					<p>Network solutions provided by <a href="https://www.shoppermedia.com.au/" target="_blank" rel="noopener noreferrer">Shopper Media Group</a></p>
				</cite>
				<figure>
					<a href="https://www.shoppermedia.com.au/" target="_blank" rel="noopener noreferrer">
						<img src={Logo} alt="Shopper Media Group" />
					</a>
				</figure>
			</footer>
        );
    }
}

export default Footer;
import axiosInstance from './axiosInstance';

export function fetchCenterCampaign({ center_id, user_id }) {
	return axiosInstance
		.get(`/center/${center_id}/campaign?user_id=${user_id}`)
		.then((result) => Promise.resolve(result.data))
		.catch((error) => Promise.reject(error));
}

export function fetchCenterWeather({ center_id, site_name }) {
	return axiosInstance
		.get(`/center/${center_id}/weather?site_name=${site_name}`)
		.then((result) => Promise.resolve(result.data))
		.catch((err) => Promise.reject(err));
}

export function fetchCenterTnc(center_id) {
	return axiosInstance
		.get(`/center/${center_id}/tnc`)
		.then((result) => Promise.resolve(result.data))
		.catch((err) => Promise.reject(err));
}
